html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* Colors */
  --white: #fff;
  --gray: #f7f7f7;
  --black: #0d0d0d;
  /* Font sizes */
  --head-font: 50px;
  --text-font: 17px;

  /* Component sizes */
  --side-padding: 25px;
  --btn-radius: 5px;
  --max-width: 1000px;
}

.image-holder {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* ANIMATIONS */
/* main */
.main-gallery-enter {
  transform: translateX(100%);
}
.main-gallery-enter-active {
  transform: translateX(0%);
  transition: transform 1000ms 100ms cubic-bezier(0.43, 0.06, 0.12, 0.95);
}
.main-gallery-exit {
  transform: translateX(0%);
}
.main-gallery-exit-active {
  transform: translateX(-100%);
  transition: transform 1000ms cubic-bezier(0.43, 0.06, 0.12, 0.95);
}
/* REVERSE */
.reverse-main-gallery-enter {
  transform: translateX(-100%);
}
.reverse-main-gallery-enter-active {
  transform: translateX(0%);
  transition: transform 1000ms 100ms cubic-bezier(0.43, 0.06, 0.12, 0.95);
}
.reverse-main-gallery-exit {
  transform: translateX(0%);
}
.reverse-main-gallery-exit-active {
  transform: translateX(100%);
  transition: transform 1000ms cubic-bezier(0.43, 0.06, 0.12, 0.95);
}

/* child */
.child-gallery-enter {
  transform: translateY(100%);
}
.child-gallery-enter-active {
  transform: translateY(0%);
  transition: transform 700ms 100ms;
}
.child-gallery-exit {
  transform: translateY(0%);
}
.child-gallery-exit-active {
  transform: translateY(-100%);
  transition: transform 800ms;
}
/* REVERSE */
.reverse-child-gallery-enter {
  transform: translateY(-100%);
}
.reverse-child-gallery-enter-active {
  transform: translateY(0%);
  transition: transform 700ms 100ms;
}
.reverse-child-gallery-exit {
  transform: translateY(0%);
}
.reverse-child-gallery-exit-active {
  transform: translateY(100%);
  transition: transform 800ms;
}
